import { useMemo } from 'react';
import { useEntitySectionTabs } from 'utils/globals';

import usePageContext from 'utils/hooks/usePageContext';
import { ENTITY_DETAILS_TAB, ENTITY_FEATURED_TABS_TITLE, ENTITY_MAIN_TABS, TP } from 'constants/index';

import { useTranslation } from 'src/i18n';

const useEntityActiveTabName = ({ enabled = true } = {}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { mainPath, entityType, entity, subPath, filterParams } = usePageContext();
  const tabs = useEntitySectionTabs({ entityType, entity, mainPath });

  const tabName = useMemo(() => {
    if (!mainPath || !enabled) {
      return '';
    }

    const activeTab = tabs?.find(({ key }) => key === mainPath);
    if (
      [
        ENTITY_DETAILS_TAB.GENERAL,
        ENTITY_DETAILS_TAB.DIGITAL,
        ENTITY_DETAILS_TAB.INTRO_SHOWREEL,
        ENTITY_DETAILS_TAB.AUDITION_SHOWREEL,
      ].includes(subPath)
    ) {
      return `${t(ENTITY_FEATURED_TABS_TITLE[subPath])} ${t(activeTab?.title)}`;
    }

    if (
      activeTab?.key === ENTITY_MAIN_TABS.PERFORMANCES &&
      subPath !== ENTITY_DETAILS_TAB.PAST &&
      !filterParams?.season_id?.[0]
    ) {
      return t(`${TP}.FN_UPCOMING_PERFORMANCES`);
    }

    if (
      activeTab?.key === ENTITY_MAIN_TABS.PERFORMANCES &&
      subPath == ENTITY_DETAILS_TAB.PAST &&
      !filterParams?.season_id?.[0]
    ) {
      return t(`${TP}.FN_PAST_PERFORMANCES`);
    }

    if (activeTab) {
      return t(activeTab?.title);
    }

    return '';
  }, [mainPath, subPath, tabs, filterParams, enabled, t]);

  return tabName;
};

export default useEntityActiveTabName;
