import React, { useMemo, createContext, useContext } from 'react';

export const THEMES = {
  LIGHT: 'light',
  DARK: 'dark',
};

const ThemeContext = createContext({ theme: THEMES.LIGHT });

export const ThemeProvider = ({ theme, children, element = 'div' }) => {
  const parentContext = useContext(ThemeContext);

  const mergedContext = useMemo(
    () => ({
      ...parentContext,
      theme: theme || parentContext?.theme || THEMES.LIGHT,
    }),
    [parentContext, theme],
  );

  const Wrapper = element || 'div';

  return (
    <ThemeContext.Provider value={mergedContext}>
      <Wrapper className={`theme-${mergedContext.theme}`}>{children}</Wrapper>
    </ThemeContext.Provider>
  );
};

const useTheme = () => useContext(ThemeContext);

export default useTheme;
