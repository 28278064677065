import React from 'react';
import { useTranslation } from 'src/i18n';
import LinkButton, { TertiaryButton } from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import Modal from 'components/uiLibrary/Modal';
import classes from 'components/Globals/FAQ/FAQ.module.scss';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

const FAQ = ({ trackingData }) => {
  const { t } = useTranslation(['NS_ENTITY_STUB_PAGE', 'NS_APP_GLOBALS']);
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <TertiaryButton
        leftIcon={<SpriteIcon icon="question_mark" size={16} />}
        size="medium"
        styles={{ root: classes.faqBtn }}
        onClick={() => setIsOpen(!isOpen)}
        trackingData={trackingData}
      >
        {t(`operabase.general.FN_FAQS`)}
      </TertiaryButton>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={t(`operabase.general.FN_FAQS`)}
        allowMobileDrawer
        className={classes.modal}
        styles={{ modalRoot: classes.modalRoot }}
      >
        <div className={classes.modalContent}>
          <FAQContent />
        </div>
      </Modal>
    </>
  );
};

const faqBlocks = [
  {
    title: 'FAQ_ARE_YOU_AN_ARTIST',
    component: COMPONENTS.ARTIST,
    questions: [
      'FAQ_Q_HOW_ACCESS',
      'FAQ_Q_HOW_TO_ADD_PERFORMANCES',
      'FAQ_Q_HOW_CAN_ADD_REPERTOIRE',
      'FAQ_Q_HOW_ADD_AGENCY_REPRESENTATION',
    ],
    linkTarget: '/pro/artists/details#qna',
  },
  {
    title: 'operabase.general.FN_PRO_VIEW_CARD_CASTING_TITLE',
    component: COMPONENTS.CASTING,
    questions: [
      'FAQ_Q_HOW_GET_ACCESS_TO_ORG_PAGE',
      'FAQ_Q_HOW_LINK_CTSUB_TO_ORG',
      'FAQ_Q_HOW_TO_ADD_TICKETS_COMP_PAGE',
    ],
    linkTarget: '/pro/casting/details#qna',
  },
  {
    title: 'FAQ_ARE_YOU_A_MANAGER',
    component: COMPONENTS.MANAGER,
    questions: ['FAQ_Q_HOW_GET_ACCESS_AGENCY', 'FAQ_Q_HOW_UPDATE_ARTIST_REPR', 'FAQ_Q_WHY_ROSTER_IMPORTANT'],
    linkTarget: '/pro/agencies/details#qna',
  },
  {
    title: 'FAQ_ARE_YOU_A_JOURNALIST',
    component: COMPONENTS.JOURNALIST,
    questions: ['FAQ_Q_HOW_GET_OB_ARCHIVE', 'FAQ_Q_HOW_COMPREHENSIVE_IS_CT'],
    linkTarget: '/pro/journalist#qna',
  },
];

const FAQContent = () => {
  const { t } = useTranslation(['NS_ENTITY_STUB_PAGE', 'NS_APP_GLOBALS']);
  return (
    <div className={classes.faqContent}>
      {faqBlocks.map((block, index) => (
        <section key={`sec_${index}`}>
          <LinkButton
            variant="text"
            isLink
            href={block.linkTarget}
            target="_blank"
            styles={{ root: classes.section }}
            disableUnderline
            trackingData={{ section: SECTIONS.FAQ_MODAL, component: block.component }}
          >
            <div className={classes.sectionTitle}>
              <Typography size="14" weight="bold" color="inherit">
                {t(block.title)}
              </Typography>
            </div>
            <div className={classes.sectionContent}>
              <ul className={classes.contentContainer}>
                {block.questions.map((question, i) => (
                  <li key={`q_${i}`}>
                    <Typography size="12" color="secondary" className={classes.faqRow}>
                      {t(question)}
                    </Typography>
                  </li>
                ))}
              </ul>
              <LinkButton
                variant="text"
                rightIcon={<SpriteIcon icon="open_in_new" size={14} className={classes.rightIcon} />}
                isLink
                href={block.linkTarget}
                target="_blank"
                disableUnderline
                skipTracking // tracked in parent LinkButton Click
              >
                <div className={classes.ctaContainer}>
                  {t('NS_APP_GLOBALS:operabase.general.m_ARTICLES_CLICK_HERE')}
                </div>
              </LinkButton>
            </div>
          </LinkButton>
        </section>
      ))}
    </div>
  );
};

export default FAQ;
