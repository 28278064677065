import React from 'react';
import classnames from 'classnames';

import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';
import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';

import usePageContext from 'utils/hooks/usePageContext';

import { useTranslation } from 'src/i18n';

import classes from './PageNavigationTabs.module.scss';

const PageNavigationTabs = ({ tabs, activeTab, resetScrollTop = true }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate } = usePageContext();

  return (
    <HorizontalScroller styles={{ root: classes.scrollbarRoot }}>
      <nav className={classes.navigation}>
        <ul className={classes.navigation__list}>
          {tabs.map((tab, index) => (
            <li className={classes.navigation__list_item}>
              <LinkButton
                styles={{
                  root: classnames(classes.navLink, {
                    [classes.isActive]: activeTab?.key === tab?.key,
                  }),
                }}
                {...tab.linkProps}
                key={`${tab?.key}_${index}`}
                onClick={() => {
                  if (resetScrollTop) {
                    navigate.scrollTo({ behavior: 'instant' });
                  }
                }}
                variant="text"
                shape="rectangle"
                isLink
                disableUnderline
                disableHover
              >
                <Typography color="none" weight={activeTab?.key === tab?.key ? 'bold' : 'regular'}>
                  {t(tab.title)}
                </Typography>
              </LinkButton>
            </li>
          ))}
        </ul>
      </nav>
    </HorizontalScroller>
  );
};

export default PageNavigationTabs;
