import React, { useState, useRef, useCallback, useEffect } from 'react';
import classnames from 'classnames';

import Link from 'components/uiLibrary/Link'; // TODO: Change to use LinkButton
import Drawer from 'components/uiLibrary/Drawer';
import Typography from 'components/uiLibrary/Typography';
import useTracking from 'components/Globals/Analytics';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useTranslation } from 'src/i18n';

import SpriteIcon from '../SpriteIcon';
import LinkButton from '../LinkButton';
import { Radio } from '../RadioGroup';

import classes from './ToggleButton.module.scss';

const RenderOption = ({
  option,
  alwaysDropdown,
  isSelected,
  isEmbedded,
  onChange,
  enabledDarkMode = false,
  styles,
  trackingData,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  if (!option?.label) {
    return null;
  }

  if (option.radio) {
    return (
      <Radio
        checked={!!isSelected}
        onChange={() => onChange(option)}
        label={option?.label}
        size="small"
        styles={{
          radio: classnames(classes.toggleBtn, classes.radioButton, {
            [classes.darkTheme]: !!enabledDarkMode,
            [styles?.radioBtn]: !!styles?.radioBtn,
          }),
          radioInput: classnames({
            [classes.toggleBtn__isSelected]: !!isSelected,
          }),
          radioLabel: classnames(classes.radioLabel, {
            [classes.radioLabel__isSelected]: !!isSelected,
          }),
        }}
      />
    );
  }

  if (option?.asLink) {
    return (
      <Link
        key={option?.value}
        {...option.linkProps}
        external={isEmbedded}
        disableUnderline
        trackingData={trackingData ? { ...trackingData, meta: { type: option?.type } } : undefined}
      >
        <button
          key={option?.value}
          className={classnames(classes.toggleBtn, classes.linkBtn, {
            [classes.darkTheme]: !!enabledDarkMode,
          })}
        >
          {option?.icon}
          <Typography size="12" color="inherit">
            {t(option?.label)}
          </Typography>
        </button>
      </Link>
    );
  }

  return (
    <button
      key={option?.value}
      className={classnames(classes.toggleBtn, {
        [classes.toggleBtn__isSelected]: isSelected,
        [classes.isDropdown]: alwaysDropdown,
        [classes.darkTheme]: !!enabledDarkMode,
      })}
      onClick={() => onChange(option)}
    >
      {option?.icon}
      <Typography size="12" color="inherit">
        {t(option?.label)}
      </Typography>
    </button>
  );
};

const OptionsWrapper = ({
  title,
  options,
  value,
  onChange,
  onCloseDrawer,
  isMobile,
  isOpen,
  alwaysDropdown,
  icon,
  isEmbedded,
  enableDarkMode,
  styles,
  trackingData,
}) => {
  const optionsList = (
    <div
      className={classnames(classes.optionsList, {
        [classes.isOpen]: isOpen,
        [classes.isDropdown]: alwaysDropdown,
        [classes.darkTheme]: enableDarkMode,
      })}
    >
      {options?.map(option => {
        const isSelected = value === option?.value;

        return (
          <RenderOption
            key={option?.value}
            option={option}
            onChange={onChange}
            isSelected={isSelected}
            isEmbedded={isEmbedded}
            alwaysDropdown={alwaysDropdown}
            enabledDarkMode={enableDarkMode}
            styles={styles}
            trackingData={trackingData}
          />
        );
      })}
    </div>
  );

  if (isMobile) {
    return (
      <Drawer isOpen={isOpen} onClose={onCloseDrawer} className={classes.drawer}>
        <div
          className={classnames(classes.drawer__header, {
            [classes.drawer__header_darkTheme]: !!enableDarkMode,
          })}
        >
          {icon && icon}
          <Typography size="16" className={classes.drawer__title} weight="bold">
            {title}
          </Typography>
          <LinkButton
            variant="text"
            styles={{ root: classes.header_closeIcon }}
            leftIcon={<SpriteIcon icon="close" size={14} />}
            onClick={onCloseDrawer}
          />
        </div>
        {optionsList}
      </Drawer>
    );
  }

  return optionsList;
};

const ToggleButton = ({
  title,
  options,
  value,
  onChange,
  label,
  leftIcon,
  rightIcon,
  alwaysDropdown,
  isEmbedded,
  enableDarkMode = false,
  onOpen,
  styles,
  trackingData,
}) => {
  const { isMobile } = useDeviceTypeLayouts();
  const { t } = useTranslation('NS_APP_GLOBALS');
  const ref = useRef();
  const track = useTracking();

  const [isOpen, setIsOpen] = useState(false);
  const selectedOption = options?.find(option => option?.value === value);

  const selectedBtnInfo = {
    label: label || (!selectedOption?.icon && t(selectedOption?.label)) || '',
    leftIcon: leftIcon || selectedOption?.icon || null,
    rightIcon: rightIcon || null,
  };

  useEffect(() => {
    if (onOpen) {
      onOpen(isOpen);
    }
  }, [onOpen, isOpen]);

  const onClickOutsideHandler = useCallback(() => {
    if (!isMobile && isOpen) {
      setIsOpen(false);
    }
  }, [isMobile, isOpen]);

  const onCloseDrawer = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onChangeHandler = useCallback(
    option => {
      onChange(option);
      setIsOpen(false);
    },
    [onChange],
  );

  useOnClickOutside(ref, onClickOutsideHandler);

  return (
    <div ref={ref} className={classes.root}>
      {(selectedBtnInfo?.icon || selectedBtnInfo?.label) && (
        <button
          className={classnames(classes.toggleBtn, classes.selectedOption, {
            [classes.selectedOption__dropdown]: alwaysDropdown,
            [classes.selectedOption__isActive]: isOpen,
            [classes.selectedOption__withoutLabel]: !selectedBtnInfo?.label,
            [classes.darkTheme]: enableDarkMode,
            [styles?.selectedOption]: !!styles?.selectedOption,
          })}
          onClick={() => {
            if (!isOpen && trackingData) {
              track.click(trackingData);
            }
            setIsOpen(!isOpen);
          }}
        >
          {selectedBtnInfo?.leftIcon}
          {selectedBtnInfo?.label && (
            <Typography size="12" color="inherit" className={classes.selectedLabel} weight="bold">
              {selectedBtnInfo?.label}
            </Typography>
          )}
          {selectedBtnInfo?.rightIcon}
        </button>
      )}
      <OptionsWrapper
        title={title}
        icon={selectedBtnInfo?.leftIcon}
        options={options}
        value={value}
        onChange={onChangeHandler}
        isOpen={isOpen}
        alwaysDropdown={alwaysDropdown}
        isEmbedded={isEmbedded}
        isMobile={isMobile}
        onCloseDrawer={onCloseDrawer}
        enableDarkMode={enableDarkMode}
        styles={styles}
        trackingData={trackingData}
      />
    </div>
  );
};

export default ToggleButton;
