import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'src/makeStyles';
import MuiTextField from '@mui/material/TextField';
import { capitalize, get } from 'lodash';
import { withTranslation } from '../../../src/i18n';
import { colors } from '../../../constants/styleConstants';
import { removeWithTranslationProps } from '../../../utils/common';

const useStyles = makeStyles()((theme, props) => ({
  root: {
    width: 270,
    '&.MuiFormControl-fullWidth': {
      width: '100%',
    },
  },
  inputRoot: {
    border: `1px solid ${colors.grey14}`,
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: colors.white,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    width: '100%',
    '&:hover': {
      backgroundColor: colors.white,
    },
    '&.Mui-disabled:hover': {
      backgroundColor: colors.blackOpacity12,
    },
    '&.Mui-focused': {
      borderColor: colors.blue3,
      boxShadow: `0px 0px 4px ${colors.blue3Opacity10}`,
      backgroundColor: colors.white,
    },
    '& .MuiFilledInput-input': {
      padding: props?.withIndicators ? '25px 0 6px 12px' : '25px 12px 6px',
      display: 'flex',
    },
    '&.Mui-error': {
      backgroundColor: colors.red1,
      borderColor: colors.redDelete,
    },
    '&.MuiInputBase-multiline': {
      padding: props?.withIndicators ? '25px 0 6px 12px' : '25px 12px 6px',
      '& .MuiFilledInput-input': {
        padding: 0,
      },
    },
    '&.MuiFilledInput-multiline': {
      padding: 0,
    },
  },
  labelRoot: {
    color: colors.grey2,
    '&.Mui-focused': {
      color: colors.grey2,
    },
    '&.Mui-error': {
      color: colors.grey2,
    },
    '& .MuiFormLabel-asterisk': {
      color: colors.redDelete,
      position: 'relative',
      top: -5,
    },
  },
  errorRoot: {
    '&.Mui-error': {
      color: colors.redDelete,
      fontStyle: 'italic',
    },
  },
}));

const TextField = ({
  t,
  field,
  id,
  className,
  form: { errors, touched, setFieldValue },
  autoCapitalizeOnPaste,
  InputProps,
  nativeInputProps,
  withIndicators,
  ...props
}) => {
  const { classes, cx } = useStyles({ withIndicators });
  const errorText =
    get(touched, field?.name) || (props?.hasError && get(errors, field?.name))
      ? get(errors, field?.name, '')
          .split(' ')
          .map(text => t(text))
          .join(' ')
      : '';

  const hasError = props.hasError || (get(touched, field?.name) && Boolean(get(errors, field?.name, '')));

  const pasteCorrection = event => {
    if (autoCapitalizeOnPaste) {
      const clipBoardValue = event?.clipboardData?.getData('Text') ?? '';
      let newValue = capitalize(clipBoardValue);

      if (event?.target?.value) {
        const element = event.target;
        const currentValue = element.value;

        newValue = currentValue?.slice(0, element?.selectionStart);
        newValue += capitalize(clipBoardValue);
        newValue += currentValue?.slice(element?.selectionEnd);

        const caretPos = element?.selectionStart + clipBoardValue?.length;
        window.requestAnimationFrame(() => {
          element.setSelectionRange(caretPos, caretPos);
        });
      }

      setFieldValue(field?.name, newValue);
      event.preventDefault();
    }
  };

  return (
    <MuiTextField
      id={id}
      variant="filled"
      {...field}
      value={field?.value ?? ''}
      InputProps={{ classes: { root: classes.inputRoot }, disableUnderline: true, autoComplete: 'off', ...InputProps }}
      InputLabelProps={{
        shrink: true,
        classes: { root: classes.labelRoot },
      }}
      FormHelperTextProps={{
        classes: { root: classes.errorRoot },
      }}
      onPaste={pasteCorrection}
      error={hasError}
      helperText={errorText}
      className={cx(classes.root, className)}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={nativeInputProps}
      {...removeWithTranslationProps(props)}
    />
  );
};

TextField.getInitialProps = async () => ({
  namespacesRequired: ['translation'],
});

TextField.propTypes = {
  t: PropTypes.func.isRequired,
  field: PropTypes.object,
  form: PropTypes.shape({
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    touched: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    setFieldValue: PropTypes.func,
  }),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  autoCapitalizeOnPaste: PropTypes.bool,
  withIndicators: PropTypes.bool,
};

TextField.defaultProps = {
  form: {
    errors: {},
    touched: {},
    setFieldValue: () => {},
  },
  field: {
    name: '',
  },
  autoCapitalizeOnPaste: false,
  withIndicators: false,
};

export default withTranslation('translation')(TextField);
