import React, { useState, useEffect } from 'react';
import { Textfit } from 'react-textfit';

const TextFit = ({ mode = 'single', text, min = 14, max = 26, textRenderer = value => value }) => {
  const [localText, setLocalText] = useState(text);

  useEffect(() => {
    // NOTE: Having a local state synced with external prop value to force TextFit component to re-render
    setLocalText(text);
  }, [text]);

  return (
    <Textfit mode={mode} min={min} max={max}>
      {textRenderer(localText)}
    </Textfit>
  );
};

export default TextFit;
