import React, { useMemo, useCallback } from 'react';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { PrimaryButton, TextButton } from 'components/uiLibrary/LinkButton';
import { COMPONENTS } from 'components/Globals/Analytics/constants';

import usePageContext from 'utils/hooks/usePageContext';
import { useDialogs } from 'utils/hooks/useDialogs';
import useEntityEditPermission from 'utils/hooks/useEntityEditPermission';
import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import useAppContext from 'utils/hooks/useAppContext';
import { setCookie } from 'utils/cookie';

import { ENTITY_TYPE, TP } from 'constants/index';
import { PROFILE_SWITCH } from 'constants/cookieConstants';

import { useTranslation } from 'src/i18n';

const useEntityEditPermissions = () => {
  const { navigate, entityType, entity } = usePageContext();
  const { language } = useAppContext();
  const { showEditBtn, ...rest } = useEntityEditPermission({
    entityType,
    entity,
  });
  const { canEdit, isVerificationPending, hasSubscription, isArtistInRoster, hasTargetProfileAccess } = rest || {};

  const { setNoSubscriptionPopup, setPendingAccessPopup, setSubscriptionNudgesPopup } = useNotificationPopup();
  const { setIsRequestAccessModalOpen, isPreviewModalOpen } = useDialogs();

  const handleCheckPermissionPopup = useCallback(
    event => {
      if (canEdit) return;

      if (isArtistInRoster && !hasSubscription && !hasTargetProfileAccess) {
        setIsRequestAccessModalOpen(true);
        return;
      }

      if (!hasSubscription) {
        event.preventDefault();

        if (entityType === ENTITY_TYPE.ARTIST) {
          setSubscriptionNudgesPopup({
            isOpen: true,
          });
          return;
        }

        setNoSubscriptionPopup(true);
        return;
      }

      if (hasTargetProfileAccess) {
        const linkProps = navigate.getLinkProps({ entityType, entity, onlyLinkProps: true });
        setCookie(PROFILE_SWITCH, true);
        window.location.href = `/auth/switchProfile?profileId=${entity?.id}&pageUrl=${linkProps?.as}&lang=${language}`;
        return;
      }

      if (isVerificationPending) {
        event.preventDefault();
        setPendingAccessPopup(true);
      }
    },
    [
      canEdit,
      isArtistInRoster,
      hasSubscription,
      hasTargetProfileAccess,
      isVerificationPending,
      setIsRequestAccessModalOpen,
      entityType,
      setNoSubscriptionPopup,
      setSubscriptionNudgesPopup,
      navigate,
      entity,
      language,
      setPendingAccessPopup,
    ],
  );

  return {
    ...rest,
    showEditBtn: showEditBtn && !isPreviewModalOpen,
    onEditClickHandler: handleCheckPermissionPopup,
  };
};

const EntityEditButton = ({ shape = 'rectangle', size, className, trackingData, asTextBtn = false }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate, entity, entityType } = usePageContext();

  const editLinkProps = useMemo(() => navigate.getLinkProps({ entity, entityType, edit: true, onlyLinkProps: true }), [
    entity,
    entityType,
    navigate,
  ]);

  const { showEditBtn, canEdit, onEditClickHandler } = useEntityEditPermissions();

  if (!showEditBtn) {
    return null;
  }

  const EditButton = asTextBtn ? TextButton : PrimaryButton;

  return (
    <EditButton
      styles={{ root: className }}
      leftIcon={<SpriteIcon icon="pencil" size={18} />}
      shape={shape}
      onClick={onEditClickHandler}
      trackingData={{
        ...trackingData,
        component: COMPONENTS.EDIT_CTA,
      }}
      {...editLinkProps}
      isLink={canEdit}
      preventDefault={!canEdit}
      size={size}
    >
      {t(entityType === ENTITY_TYPE.ARTIST ? `${TP}.FN_MANAGE_PROFILE` : `${TP}.m_EDIT`)}
    </EditButton>
  );
};

export { useEntityEditPermissions };
export default EntityEditButton;
