import { useRef, useCallback, useEffect } from 'react';
import { useOverlayScrollbars as useOriginalOverlayScrollbars } from 'overlayscrollbars-react';

const useOverlayScrollbars = ({ options, events, defer = true }) => {
  const { autoInit = true, ...restOptions } = options || {};
  const ref = useRef(null);

  const [initializeInstance, getScrollbarInstance] = useOriginalOverlayScrollbars({
    options: restOptions,
    events,
    defer,
  });

  useEffect(() => {
    if (autoInit && ref.current) {
      initializeInstance(ref.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeInstance]);

  const initializeScrollbar = useCallback(() => {
    if (ref.current) {
      initializeInstance(ref.current);
    }
  }, [initializeInstance]);

  return {
    ref,
    initializeScrollbar,
    getScrollbarInstance,
  };
};

export default useOverlayScrollbars;
