/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useMemo, Fragment } from 'react';
import classnames from 'classnames';

import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import TextFit from 'components/uiLibrary/TextFit';
import EntityName from 'components/Globals/EntityName';
import LinkButton from 'components/uiLibrary/LinkButton';

import usePageContext from 'utils/hooks/usePageContext';
import { getH1FilterString } from 'utils/globals/filters';
import { getMediaSource } from 'utils/media';
import useEntityActiveTabName from 'utils/hooks/useEntityActiveTabName';

import { ENTITY_TYPE } from 'constants/index';

import classes from './EntityPageH1.module.scss';

const EntityPageH1 = ({
  skipFilters = false,
  maxFontSize = 26,
  minFontSize = 14,
  skipEntityClick,
  onEntityCustomClick,
}) => {
  const { navigate, entity, entityType, appliedFilterSlugs, mainPath } = usePageContext();

  const entityLinkProps = useMemo(() => navigate.getLinkProps({ entityType, entity, onlyLinkProps: true }), [
    navigate,
    entityType,
    entity,
  ]);

  const onEntityClickHandler = useCallback(
    e => {
      if (!skipEntityClick) {
        if (onEntityCustomClick) {
          onEntityCustomClick(e);
        } else {
          navigate.to(entityLinkProps);
          navigate.scrollTo({ behavior: 'auto' });
        }
      }
    },
    [skipEntityClick, entityLinkProps, navigate, onEntityCustomClick],
  );

  const activeTabName = useEntityActiveTabName({ enabled: !skipFilters });

  const { titleParts, image } = useMemo(() => {
    const appliedFilters = getH1FilterString({ filters: appliedFilterSlugs, tabKey: mainPath, asParts: true });

    let headline = '';
    switch (entityType) {
      case ENTITY_TYPE.ARTIST: {
        headline = entity?.metaInfo?.subTitle;
        break;
      }
      case ENTITY_TYPE.ORGANIZATION: {
        headline = `${entity?.metaInfo?.subTitle} | ${entity?.organizationType?.name}`;
        break;
      }
      default: {
        headline = '';
      }
    }

    return {
      titleParts: [
        {
          title: <EntityName entityType={entityType} entity={entity} />,
          headline,
          onClick: onEntityClickHandler,
        },
        ...(!skipFilters && activeTabName
          ? [
              {
                title: activeTabName,
              },
            ]
          : []),
        ...(skipFilters ? [] : appliedFilters.map(title => ({ title }))),
      ],
      image: getMediaSource(
        {
          id: entity?.id,
          file: { urls: entity?.image || entity?.logo },
        },
        true,
      ),
    };
  }, [appliedFilterSlugs, mainPath, entityType, entity, onEntityClickHandler, activeTabName, skipFilters]);

  return (
    <HorizontalScroller styles={{ scrollable: classes.scrollablePageH1 }}>
      <div className={classes.pageH1}>
        {image && (
          <LinkButton
            variant="native"
            styles={{ root: classes.pageH1__image }}
            {...entityLinkProps}
            onClick={onEntityClickHandler}
            preventDefault
            isLink
          >
            <Image
              src={image}
              transformations={
                entityType === ENTITY_TYPE.ARTIST ? TRANSFORMATIONS.PROFILE_THUMBNAIL : TRANSFORMATIONS.AOS_LOGO
              }
              height={38}
              width={38}
              alt={entity?.metaInfo?.title}
              title={entity?.metaInfo?.title}
              disableNextImage
            />
          </LinkButton>
        )}
        <div className={classes.pageH1__title}>
          <TextFit
            text={titleParts}
            max={maxFontSize}
            min={minFontSize}
            textRenderer={text => (
              <h1 className={classes.title}>
                {text.map((part, index) => (
                  <Fragment key={index}>
                    {index > 0 && ' / '}
                    <span
                      className={classnames(classes.title_part, { [classes.title_part__with_click]: !!part.onClick })}
                      data-headline={part.headline}
                      onClick={part.onClick}
                    >
                      {part.title}
                    </span>
                  </Fragment>
                ))}
              </h1>
            )}
          />
        </div>
      </div>
    </HorizontalScroller>
  );
};

export default EntityPageH1;
