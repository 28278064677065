/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';
import classnames from 'classnames';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import Drawer from 'components/uiLibrary/Drawer';
import EntityEditButton, { useEntityEditPermissions } from 'components/Globals/EntityEditButton';
import EntityPageH1 from 'components/Globals/EntityPageH1';

import usePageContext from 'utils/hooks/usePageContext';
import { useEntitySectionTabs } from 'utils/globals';

import { TP, ENTITY_TYPE, ENTITY_MAIN_TABS } from 'constants/index';

import { useTranslation } from 'src/i18n';

import classes from './EntityProfileMenu.module.scss';

const MenuHeader = ({ menuActionLabel }) => (
  <div className={classes.menuHeader}>
    <div className={classes.menuHeader_page_type}>
      <SpriteIcon icon="menu" size={24} />
      <Typography size={14} color="primary" weight="bold">
        {menuActionLabel}
      </Typography>
    </div>
    <EntityPageH1 maxFontSize={20} minFontSize={20} skipFilters />
  </div>
);

const MenuContent = ({ onClose }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate, entityType, entity, mainPath } = usePageContext();
  const tabs = useEntitySectionTabs({ entityType, entity, mainPath });
  const activeTab = tabs?.find(({ key }) => key === mainPath) || tabs?.[0];

  const onClickHandler = useCallback(
    tab => {
      navigate.scrollTo({ behavior: 'instant' });
      navigate.to(tab.linkProps);
      onClose();
    },
    [navigate, onClose],
  );

  return (
    <nav className={classes.menu}>
      <ul className={classes.menu__list}>
        {tabs.map(({ key, ...tab }) => (
          <li
            key={key}
            className={classnames(classes.menu__list_item, { [classes.active]: key === activeTab.key })}
            onClick={() => onClickHandler(tab)}
          >
            {t(tab.title)}
          </li>
        ))}
      </ul>
    </nav>
  );
};

const MenuDrawer = ({ isOpen, onClose, menuActionLabel }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Drawer
      styles={{ header: classes.drawerHeader }}
      onClose={onClose}
      header={<MenuHeader menuActionLabel={menuActionLabel} />}
      hideHeaderBorder
      hideHeaderCloseIcon
      isOpen
    >
      <div className="menu">
        <MenuContent onClose={onClose} />
      </div>
    </Drawer>
  );
};

const EntityProfileMenu = ({ showOnlyMenu }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate, entityType, entity } = usePageContext();
  const { showEditBtn } = useEntityEditPermissions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuActionLabel = entityType === ENTITY_TYPE.ARTIST ? t(`${TP}.FN_PROFILE_MENU`) : t(`${TP}.FN_PAGE_MENU`);

  const futurePeformanceStats = entity?.stats?.performances?.future;
  const hasUpcomingPerformances = futurePeformanceStats?.exists;

  const inline = showOnlyMenu || (!hasUpcomingPerformances && !showEditBtn);

  const onNavigateToPerformances = useCallback(() => {
    const linkProps = navigate.getLinkProps({ entity, entityType, path: ENTITY_MAIN_TABS.PERFORMANCES });

    navigate.to(linkProps);
  }, [entity, entityType, navigate]);

  return (
    <>
      <div className={classnames(classes.root, { [classes.inline]: inline })}>
        <div className={classnames(classes.root_btn, classes.root_primary)} onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <SpriteIcon icon="menu" size={24} />
          <Typography color="inherit">{menuActionLabel}</Typography>
        </div>
        {!showOnlyMenu && (
          <>
            {showEditBtn && <EntityEditButton className={classnames(classes.root_btn, classes.root_tertiary)} />}
            {!showEditBtn && hasUpcomingPerformances && (
              <div className={classnames(classes.root_btn, classes.root_secondary)} onClick={onNavigateToPerformances}>
                <Typography color="inherit">
                  {t(`${TP}.FN_UPCOMING_PERFORMANCES`)}{' '}
                  {futurePeformanceStats?.total > 0 &&
                    `(${t(`${TP}.FN_PERFORMANCE_DATES_COUNT`, { count: futurePeformanceStats?.total })})`}
                </Typography>
                <SpriteIcon icon="chevron_right" size={24} />
              </div>
            )}
          </>
        )}
      </div>
      <MenuDrawer menuActionLabel={menuActionLabel} isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
    </>
  );
};

export default EntityProfileMenu;
