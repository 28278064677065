import { useMemo } from 'react';
import { useQuery } from 'utils/react-query';

import queries from 'containers/Accounts/queries';
import agencyQueries from 'containers/Managers/queries';
import { SUBSCRIPTIONS_STATUSES } from 'containers/UserContainers/AccountSettingsContainer/constants';
import usePermissions from 'utils/permissions';
import {
  ENTITY_TYPE,
  PROFILE_TYPES,
  VALIDATION_STATUS,
  PROFILE_TYPE_IDS,
  VALID_AGENCY_STATUSES,
} from 'constants/index';
import { useAccessPendingProfileIds, useActiveProfileSubscriptions } from './useAuthenticatedUser';

const useEntityEditPermission = ({ entityType, entity }) => {
  const permissions = usePermissions(entity?.id);
  const {
    userData,
    activeProfile,
    isTargetMyProfile,
    isTargetMyOrganization,
    isAdmin,
    artistPermissions,
    organizationPermissions,
    userProfiles,
  } = permissions;
  const isArtistProfile = entityType === ENTITY_TYPE.ARTIST;

  const activeProfileSubscriptions = useActiveProfileSubscriptions();
  const profileIdsPendingAccess = useAccessPendingProfileIds();
  const hasTargetProfileAccess = !!userProfiles?.find(profile => profile?.id === entity?.id);
  const agencyId = useMemo(() => {
    const agencyProfiles = userProfiles?.filter(
      profile =>
        profile?.profileType?.id === PROFILE_TYPE_IDS.AGENT &&
        profile?.agencies?.some(agency => VALID_AGENCY_STATUSES.includes(agency?.status)),
    );

    return agencyProfiles?.[0]?.agencies?.find(agency => VALID_AGENCY_STATUSES.includes(agency?.status))?.id;
  }, [userProfiles]);

  const { data: artistsInRoaster } = useQuery(
    agencyQueries.getAgencyArtists({
      agencyId,
      queryConfig: {
        enabled: !!agencyId,
        select: response => response?.data?.map(artist => artist?.artist?.profile?.id),
      },
    }),
  );

  const isArtistInRoster = agencyId
    ? [PROFILE_TYPES.PRO, PROFILE_TYPES.FREEMIUM].includes(activeProfile?.subscriptionStatus) &&
      artistsInRoaster?.includes(entity?.id)
    : false;

  const hasArtistProfileAccess = useMemo(
    () =>
      isArtistProfile &&
      (isTargetMyProfile || userProfiles?.some(profile => profile?.id === entity?.id) || isArtistInRoster),
    [isArtistProfile, isTargetMyProfile, userProfiles, isArtistInRoster, entity?.id],
  );

  const isVerificationPending = isArtistProfile
    ? entity?.validationStatus?.slug === VALIDATION_STATUS.PENDING || profileIdsPendingAccess?.includes(entity?.id)
    : !!activeProfile?.pendingValidations?.length || entity?.validationStatus?.slug === VALIDATION_STATUS.PENDING;

  const { data: profileAccesses } = useQuery(
    queries.getProfileAccesses({
      userId: userData?.id,
      profileId: entity?.id,
      queryConfig: { enabled: hasArtistProfileAccess && !!userData?.id },
    }),
  );

  const { data: profileSubscriptions, isLoading } = useQuery(
    queries.getSubscriptions({
      profileId: entity?.id,
      queryConfig: { enabled: hasArtistProfileAccess && !!userData?.id },
    }),
  );

  const { data: registrations } = useQuery(
    queries.getRegistrations({ userId: userData?.id, queryConfig: { enabled: !!userData?.id } }),
  );

  const activeRegistration = useMemo(
    () => registrations?.data?.find(reg => reg?.entities?.profile?.id === activeProfile.id),
    [activeProfile.id, registrations?.data],
  );
  const getSubscriptionStatus = (sub, isSharedProfile, userDataId) => {
    const validStatuses = [
      SUBSCRIPTIONS_STATUSES.inTrial,
      SUBSCRIPTIONS_STATUSES.active,
      SUBSCRIPTIONS_STATUSES.cancelled,
      SUBSCRIPTIONS_STATUSES.nonRenewing,
    ];

    if (isSharedProfile) {
      const senderUserId = profileAccesses?.data?.[0]?.senderUser?.id;
      return sub?.user?.id === senderUserId && validStatuses?.includes(sub?.status);
    }

    return sub?.user?.id === userDataId && validStatuses?.includes(sub?.status);
  };
  const profileSubscription = useMemo(() => {
    const isSharedProfile = profileAccesses?.data?.[0]?.shareAccess;
    const userDataId = userData?.id;

    return profileSubscriptions?.data?.find(sub => getSubscriptionStatus(sub, isSharedProfile, userDataId));
  }, [userData?.id, profileSubscriptions?.data, profileAccesses?.data]);

  const showEditBtn = hasArtistProfileAccess || isTargetMyOrganization || isAdmin;
  const canEditArtistProfile =
    isArtistProfile &&
    !!activeProfileSubscriptions?.length &&
    !isVerificationPending &&
    artistPermissions?.canEdit &&
    !userData?.isSuspicious;
  const canEditOrganization = entityType === ENTITY_TYPE.ORGANIZATION && organizationPermissions?.canEdit;
  const canEdit = (isLoading && isTargetMyProfile) || isAdmin || canEditArtistProfile || canEditOrganization;
  const hasSubscription = isArtistProfile
    ? (isLoading && isTargetMyProfile) || !!profileSubscription
    : activeProfile?.subscriptionStatus === PROFILE_TYPES.PRO;
  return {
    showEditBtn,
    canEdit,
    isVerificationPending,
    hasSubscription,
    hasTargetProfileAccess,
    isArtistInRoster,
    profileAccesses,
    activeRegistration,
    profileSubscription,
  };
};

export default useEntityEditPermission;
