import React, { useMemo } from 'react';

import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';

import { useTranslation } from 'src/i18n';
import { useEntitySectionTabs } from 'utils/globals';
import usePageContext from 'utils/hooks/usePageContext';
import { ENTITY_MAIN_TABS, TP, BASE_PAGE_ROUTES, ENTITY_TYPE, OPERABASE_MAIN_ROUTES } from 'constants/index';

import SectionBlock from '../../../SectionBlock';
import classes from './Explore.module.scss';

const Explore = ({ trackingData }) => {
  const { t } = useTranslation(['NS_ENTITY_STUB_PAGE', 'NS_APP_GLOBALS']);
  const { navigate, entityType, entity, activeTabKey } = usePageContext();
  const tabs = useEntitySectionTabs({ entityType, entity });
  const proTabs = useEntitySectionTabs({ entityType, entity, baseRoute: BASE_PAGE_ROUTES.CASTING });

  const { paths, sectionTitle } = useMemo(() => {
    switch (entityType) {
      case ENTITY_TYPE.ORGANIZATION:
      case ENTITY_TYPE.ARTIST: {
        const filteredProTabs =
          entityType === ENTITY_TYPE.ARTIST
            ? proTabs
                ?.filter(
                  ({ key }) =>
                    ![ENTITY_MAIN_TABS.BIO, ENTITY_MAIN_TABS.REPERTOIRE, ENTITY_MAIN_TABS.PERFORMANCES].includes(key),
                )
                ?.map(tab => ({ ...tab, isProTab: true }))
            : [];
        const exceptCurrentTabList = tabs?.filter(({ key }) =>
          activeTabKey ? key !== activeTabKey : key !== ENTITY_MAIN_TABS.HOME,
        );

        return {
          paths: [...exceptCurrentTabList, ...filteredProTabs],
          sectionTitle: t(`${TP}.m_EXPLORE`, { entityName: entity?.name }),
        };
      }
      default: {
        const mainPaths = OPERABASE_MAIN_ROUTES.map(page => ({
          ...page,
          title: `NS_APP_GLOBALS:${page.title}`,
          linkProps: navigate.getLinkProps({ baseRoute: page.path }),
        }));

        return {
          paths: mainPaths,
          sectionTitle: t(`NS_APP_GLOBALS:${TP}.FN_AUDIENCE_ONBOARDING_CTA`),
        };
      }
    }
  }, [entityType, proTabs, tabs, t, entity?.name, activeTabKey, navigate]);

  return (
    <SectionBlock title={sectionTitle}>
      <div className={classes.root}>
        {paths.map(({ key, title, linkProps, isProTab }) => (
          <LinkButton
            key={`${key}${isProTab ? `__${isProTab}` : ''}`}
            variant="secondary"
            {...linkProps}
            isLink
            onClick={() => {
              navigate.scrollTo({ behavior: 'auto' });
            }}
            styles={{ root: classes.button }}
            title={t(title)}
            trackingData={{
              ...trackingData,
              subComponent: key,
              meta: {
                title: t(title),
              },
            }}
          >
            <Typography color="secondary" size={14} weight="medium">
              {t(title)}
            </Typography>
            {isProTab && (
              <Typography className={classes.pro} size={12} weight="medium">
                {t(`${TP}.FN_PRO`)}
              </Typography>
            )}
          </LinkButton>
        ))}
      </div>
    </SectionBlock>
  );
};

export default Explore;
