/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback } from 'react';
import dynamic from 'next/dynamic';

const Lightbox = dynamic(() => import('components/uiLibrary/Lightbox'));

const withLightbox = Component => props => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [defaultMediaId, setDefaultMediaId] = useState(null);
  const [mediaContent, setMediaContent] = useState(null);
  const [trackingData, setTrackingData] = useState(null);

  const onOpenLightbox = useCallback(params => {
    setData(params.data || []);
    setDefaultMediaId(params.mediaId || null);
    setTrackingData(params.trackingData || null);
    setMediaContent(params?.mediaContent || props?.entity?.metaInfo || null);
    setIsOpen(true);
  }, []);

  const onCloseLightbox = useCallback(() => {
    setData([]);
    setDefaultMediaId(null);
    setTrackingData(null);
    setIsOpen(false);
  }, []);

  return (
    <>
      <Component {...props} showLightbox={onOpenLightbox} closeLightbox={onCloseLightbox} />
      {isOpen && (
        <Lightbox
          data={data}
          defaultMediaId={defaultMediaId}
          onClose={onCloseLightbox}
          mediaContent={mediaContent}
          trackingData={trackingData}
          isOpen
        />
      )}
    </>
  );
};

export default withLightbox;
