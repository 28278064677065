import React, { useMemo } from 'react';
import classnames from 'classnames';
import { makeStyles } from 'src/makeStyles';
import Alert from '@mui/material/Alert';
import usePageContext from 'utils/hooks/usePageContext';
import NextLink from '../NextLink';
import { useTranslation, Trans } from '../../src/i18n';
import { colors } from '../../constants/styleConstants';
import { SUSPENSION_CATEGORY, TP } from '../../constants/consts';

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    margin: '10px 0',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  alert: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: colors.background.lightBlue2,
  },
  link: {
    color: colors.blueChip,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  bold: {
    fontWeight: 'bold',
  },
  highlightEntity: {
    color: colors.blueChip,
    fontWeight: 'bold',
  },
}));

const EntityStatus = ({ entityInfo, entityType, className }) => {
  const { navigate } = usePageContext();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const suspension = entityInfo?.suspension;

  const { linkProps, newEntity } = useMemo(() => {
    if (suspension?.operationalAgency?.id) {
      return {
        linkProps: navigate.getLinkProps({ entity: suspension.operationalAgency, entityType }),
        newEntity: suspension?.operationalAgency?.name,
      };
    }

    if (suspension?.operationalOrganization?.id) {
      return {
        linkProps: navigate.getLinkProps({ entity: suspension.operationalOrganization, entityType }),
        newEntity: suspension?.operationalOrganization?.name,
      };
    }
    return { linkProps: {}, newEntity: '' };
  }, [entityType, navigate, suspension?.operationalAgency, suspension?.operationalOrganization]);

  const isEntityMerged = suspension?.category === SUSPENSION_CATEGORY.ACQUISITION;
  const isEntityRebranded = suspension?.category === SUSPENSION_CATEGORY.REBRANDING;
  const isNonOperational = suspension?.category === SUSPENSION_CATEGORY.REGULAR;
  const rootClassName = classnames(classes.alert, className);

  if (isEntityMerged) {
    return (
      <Alert className={rootClassName} icon={false}>
        <Trans
          i18nKey={`${TP}.FN_ENTITY_MERGED`}
          components={{
            entityUrl: <NextLink className={classes.link} {...linkProps} />,
            bold: <span className={classes.bold} />,
            highlight: <span className={classes.highlightEntity} />,
          }}
          values={{
            entity: entityInfo?.name,
            newEntity,
          }}
        />
      </Alert>
    );
  }

  if (isEntityRebranded) {
    return (
      <Alert className={rootClassName} icon={false}>
        <Trans
          i18nKey={`${TP}.FN_ENTITY_REBRANDED`}
          components={{
            entityUrl: <NextLink className={classes.link} {...linkProps} />,
            bold: <span className={classes.bold} />,
            highlight: <span className={classes.highlightEntity} />,
          }}
          values={{
            entity: entityInfo?.name,
            newEntity,
          }}
        />
      </Alert>
    );
  }

  return (
    <>
      {isNonOperational && (
        <Alert className={rootClassName} severity="info">
          <span>{t(`${TP}.FN_NON_OPERATIONAL_ENTITY`, { entity: entityInfo?.name })}</span>
        </Alert>
      )}
    </>
  );
};

export default EntityStatus;
