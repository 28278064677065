import React, { useMemo } from 'react';
import classnames from 'classnames';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';
import EntityShareAccess from 'components/Globals/EntityShareAccess';
import ShareEntity from 'components/Globals/ShareEntity';
import Loader from 'components/uiLibrary/Loader';

import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';
import { useQuery } from 'utils/react-query';
import queries from 'containers/Productions/queries';
import ProductionWatchOptions from 'components/Productions/Display/ProductionWatchOptions';

import { TP, ENTITY_MAIN_TABS } from 'constants/index';

import classes from './OrgHeaderActionsList.module.scss';

const LsVodInformation = ({ upcomingProductionId }) => {
  const { data: production, isLoading } = useQuery(
    queries.getProductionDetails({ id: upcomingProductionId, queryConfig: { enabled: !!upcomingProductionId } }),
  );

  const { mediaInfo, productionDates } = useMemo(
    () => ({
      productionDates: production?.performances,
      mediaInfo: {
        languages: production?.languages,
        subtitles: production?.subtitles,
      },
    }),
    [production],
  );

  return <>{isLoading ? <Loader /> : <ProductionWatchOptions entity={{ dates: productionDates, mediaInfo }} />}</>;
};

const OrgHeaderActionsList = ({ entityType, entity, onClose, showEditBtn, handleCheckPermissionPopup }) => {
  const { t } = useTranslation(['NS_ENTITY_STUB_PAGE', 'NS_APP_GLOBALS']);
  const { navigate } = usePageContext();
  const hasTickets = entity?.stats?.tickets?.exists;
  const upcomingProductionId = entity?.stats?.meta?.upcomingProductionId;

  const actionOptions = useMemo(
    () => ({
      ...(showEditBtn && {
        edit: {
          label: t(`${TP}.m_EDIT`),
          icon: <SpriteIcon icon="edit" size={24} className={classes.icon} />,
          linkProps: navigate.getLinkProps({ entityType, entity, edit: true, onlyLinkProps: true }),
          isLink: true,
          edit: true,
        },
      }),
      ...(hasTickets && {
        tickets: {
          label: t(`${TP}.FN_TICKETS`),
          icon: <SpriteIcon icon="tickets" size={24} className={classes.icon} />,
          isLink: true,
          linkProps: navigate.getLinkProps({
            entityType,
            entity,
            path: ENTITY_MAIN_TABS.PERFORMANCES,
            onlyLinkProps: true,
          }),
        },
      }),
    }),
    [entity, entityType, showEditBtn, hasTickets, navigate, t],
  );

  return (
    <ul className={classes.orgActionsList}>
      {Object.keys(actionOptions)?.map(actionKey => {
        const { label, icon, linkProps, isLink } = actionOptions[actionKey] || {};
        return (
          <li className={classes.orgActionsList__item}>
            {icon}
            <LinkButton
              variant="text"
              styles={{ root: classes.link }}
              {...linkProps}
              isLink={isLink}
              onClick={onClose}
              {...(actionOptions?.edit ? { onClick: handleCheckPermissionPopup } : {})}
            >
              {label}
            </LinkButton>
          </li>
        );
      })}
      <li className={classes.orgActionsList__item}>
        <ShareEntity
          className={classes.shareEntity}
          showLabelText={false}
          customLabelText={t(`${TP}.m_SOCIAL_SHARE`, { ns: 'NS_APP_GLOBALS' })}
        />
      </li>
      {showEditBtn && (
        <li className={classes.orgActionsList__item}>
          <EntityShareAccess entityType={entityType} entity={entity} isMoreAction />
        </li>
      )}
      {upcomingProductionId && (
        <li className={classnames(classes.orgActionsList__item, classes.watchOptions)}>
          <LsVodInformation upcomingProductionId={upcomingProductionId} />
        </li>
      )}
    </ul>
  );
};

export default OrgHeaderActionsList;
