import React, { useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import dynamic from 'next/dynamic';

import useTheme, { ThemeProvider, THEMES } from 'components/Globals/Layout/ThemeProvider';
import Breadcrumbs from 'components/uiLibrary/Breadcrumbs';
import MediaWidget from 'components/Media/Display/MediaWidget';
import PageNavigationTabs from 'components/uiLibrary/PageNavigationTabs';
import Actions from 'components/Globals/Layout/StubPage/Actions';
import EntitySuggestions from 'components/Globals/EntitySuggestions';
import { SECTIONS, COMPONENTS, CATEGORIES } from 'components/Globals/Analytics/constants';
import EntityProfileMenu from 'components/Globals/EntityProfileMenu';
import EntityPageH1 from 'components/Globals/EntityPageH1';
import ShareEntity from 'components/Globals/ShareEntity';
import EntityStatus from 'components/EntityStatus';
import PageWrapper from 'components/Globals/Layout/PageWrapper';

import usePageContext from 'utils/hooks/usePageContext';
import { useBreadcrumbs, useEntitySEO } from 'utils/seo/index';
import { useEntitySectionTabs } from 'utils/globals';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useQuery } from 'utils/react-query';
import useOnScrollBeyondElement from 'utils/hooks/useOnScrollBeyondElement';

import { ENTITY_TYPE } from 'constants/index';

import mediaQueries from 'containers/Media/queries';

import { useTranslation } from 'src/i18n';

import classes from './ResultPageLayout.module.scss';

const ArtistPremiumSections = dynamic(() => import('components/Artist/Display/ArtistPremiumSections'));

const artistInlineSuggestionsTrackingData = {
  section: SECTIONS.RECOMMENDED_ARTISTS,
  component: COMPONENTS.ARTIST_CARD,
  category: CATEGORIES.RECOMMENDED,
};

const claimProfileTrackingData = {
  section: SECTIONS.RECOMMENDED_ARTISTS,
  component: COMPONENTS.CLAIM_OR_CREATE_PROFILE,
};

const PremiumSection = () => (
  <div className={classes.section}>
    <div className={classnames(classes.section__container, classes.noEdgePadding)}>
      <ArtistPremiumSections />
    </div>
  </div>
);

const StickyHeader = ({ tabs, activeTab, isScrolledBeyond }) => {
  const {
    navigate,
    entityType,
    entity,
    hideGlobalHeader,
    hideGlobalNavigation,
    showGlobalHeader,
    showGlobalNavigation,
  } = usePageContext();
  const { isDesktop } = useDeviceTypeLayouts();

  useEffect(() => {
    if (isScrolledBeyond) {
      hideGlobalHeader();
      hideGlobalNavigation();
    } else {
      showGlobalHeader();
      showGlobalNavigation();
    }
  }, [isScrolledBeyond, hideGlobalHeader, hideGlobalNavigation, showGlobalHeader, showGlobalNavigation]);

  if (!isScrolledBeyond) {
    return null;
  }

  return (
    <div className={classes.stickyHeader}>
      <div className={classes.stickyHeader__container}>
        <ThemeProvider theme={THEMES.LIGHT}>
          <div className={classes.stickyHeaderContent}>
            <div className={classes.stickyHeaderContent__entity}>
              <EntityPageH1
                maxFontSize={20}
                minFontSize={20}
                onEntityCustomClick={() => navigate.scrollTo({ behaviour: 'auto' })}
                skipFilters
              />
            </div>
            <div className={classes.stickyHeaderContent__menu}>
              {isDesktop ? (
                <PageNavigationTabs tabs={tabs} activeTab={activeTab} />
              ) : (
                <ThemeProvider theme={THEMES.DARK}>
                  <EntityProfileMenu showOnlyMenu />
                </ThemeProvider>
              )}
              <ShareEntity
                entityType={entityType}
                entity={entity}
                className={classes.shareEntityListItem}
                trackingData={{
                  component: COMPONENTS.SHARE_CTA,
                }}
                showLabelText={false}
              />
            </div>
          </div>
        </ThemeProvider>
      </div>
    </div>
  );
};

const EntityMobileMenu = ({ tabs, activeTab, showOnlyMenu }) => {
  const { ref, isScrolledBeyond } = useOnScrollBeyondElement({ topPosition: 93 });

  return (
    <div className={classes.section}>
      <div className={classes.section__container} ref={ref}>
        <EntityProfileMenu showOnlyMenu={showOnlyMenu} />
      </div>
      <StickyHeader tabs={tabs} activeTab={activeTab} isScrolledBeyond={isScrolledBeyond} />
    </div>
  );
};

const MediaWidgetWrapper = ({ breadcrumbs }) => {
  const { entityType, entity } = usePageContext();

  return (
    <div className={classes.section}>
      <div className={classnames(classes.section__container, classes.noEdgePadding)}>
        <MediaWidget entityType={entityType} entity={entity} breadcrumbs={breadcrumbs} />
      </div>
    </div>
  );
};

const SuggestedEntities = () => {
  const { entityType, entity } = usePageContext();

  return (
    <div className={classnames(classes.section, classes.section__separator)}>
      <div className={classes.section__container}>
        <EntitySuggestions
          entityType={entityType}
          entity={entity}
          trackingData={artistInlineSuggestionsTrackingData}
          claimProfileTrackingData={claimProfileTrackingData}
          inline
        />
      </div>
    </div>
  );
};

const PageActions = ({ entityType, entity }) => (
  <div>
    <Actions
      entityType={entityType}
      entity={entity}
      trackingData={{
        section: SECTIONS.ENTITY_HEADER,
      }}
    />
  </div>
);

const ResultPageHeader = ({ breadcrumbs, tabs, activeTab }) => {
  const { entity, entityType } = usePageContext();
  const { isMobile, isDesktop } = useDeviceTypeLayouts();
  const { theme } = useTheme();
  const { ref, isScrolledBeyond } = useOnScrollBeyondElement({ topPosition: 93 });

  return (
    <>
      <div className={classes.section}>
        <div className={classes.section__container}>
          <div className={classes.breadcrumbs}>
            <Breadcrumbs
              data={breadcrumbs}
              {...(isMobile && { maxItems: 2 })}
              condensed
              overlay={theme === THEMES.DARK}
            />
          </div>
          {!isEmpty(entity?.suspension) && (
            <EntityStatus entityInfo={entity} entityType={entityType} className={classes.entityStatus} />
          )}
          <header className={classes.pageHeader} ref={ref}>
            <EntityPageH1 activeTab={activeTab} />
            {isDesktop && <PageActions entity={entity} entityType={entityType} />}
          </header>
          {isDesktop && (
            <div className={classes.navigations}>
              <PageNavigationTabs tabs={tabs} activeTab={activeTab} />
            </div>
          )}
        </div>
      </div>
      <StickyHeader tabs={tabs} activeTab={activeTab} isScrolledBeyond={isScrolledBeyond} />
    </>
  );
};

const ResultPageLayout = ({ children }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { entityId, entity, entityType, appliedFilterSlugs, mainPath } = usePageContext();
  const { isDesktop } = useDeviceTypeLayouts();
  const isPaidEntity = entity?.stats?.isPro;

  const tabs = useEntitySectionTabs({ entityType, entity, mainPath });

  const activeTab = tabs?.find(({ key }) => key === mainPath) || tabs?.[0];

  const seo = useEntitySEO({ entityType, entity, activeTab });

  const breadcrumbs = useBreadcrumbs({
    entityType,
    entity,
    appliedFilterSlugs,
    ...(activeTab?.title && { activeTab: { ...activeTab, translatedTitle: t(activeTab?.title) } }),
  });

  const premiumThemeContext = useMemo(
    () => (entityType === ENTITY_TYPE.ARTIST && isPaidEntity ? THEMES.DARK : THEMES.LIGHT),
    [entityType, isPaidEntity],
  );

  const shouldRenderMediaWidget =
    !mainPath &&
    !!entity?.id &&
    [ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION].includes(entityType) &&
    entity?.stats?.media?.widget?.exists;

  const { data: hasMediaWidget = false } = useQuery(
    mediaQueries.getMediaSuggestions({
      entityType,
      entityId,
      queryConfig: {
        enabled: shouldRenderMediaWidget,
        select: response => response?.data?.length > 0,
      },
    }),
  );

  const showPageHeader = useMemo(() => {
    if (!entityId) {
      return true;
    }

    if (!mainPath) {
      return isDesktop || !(!isDesktop && shouldRenderMediaWidget && hasMediaWidget);
    }

    return true;
  }, [entityId, hasMediaWidget, isDesktop, mainPath, shouldRenderMediaWidget]);

  return (
    <PageWrapper seo={seo} entityType={entityType}>
      <ThemeProvider theme={premiumThemeContext}>
        <div className={classes.header}>
          {entityType === ENTITY_TYPE.ARTIST && !isPaidEntity && <SuggestedEntities />}
          {showPageHeader && <ResultPageHeader breadcrumbs={breadcrumbs} tabs={tabs} activeTab={activeTab} />}
          {shouldRenderMediaWidget && hasMediaWidget && <MediaWidgetWrapper breadcrumbs={breadcrumbs} />}
          {!isDesktop && <EntityMobileMenu tabs={tabs} activeTab={activeTab} showOnlyMenu={mainPath} />}
          {!!entityId && entityType === ENTITY_TYPE.ARTIST && isPaidEntity && !mainPath && <PremiumSection />}
        </div>
        <ThemeProvider theme={THEMES.LIGHT}>
          <div>{children}</div>
        </ThemeProvider>
      </ThemeProvider>
    </PageWrapper>
  );
};

export default ResultPageLayout;
