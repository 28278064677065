/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Children, useCallback, useMemo, useState, useRef, useEffect, forwardRef } from 'react';
import classnames from 'classnames';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import useSwipeEvents from 'utils/hooks/useSwipeEvents';

import classes from './Carousel.module.scss';

const ListItems = forwardRef(
  ({ children, activeSlideIndex, swipeEvents, inlineStyles, maxVisibleItems, styles, wrap }, ref) => (
    <ul
      className={classnames(classes.content, { [styles?.content]: !!styles?.content, [classes.wrap]: wrap })}
      {...(ref && { ref })}
      {...(inlineStyles?.list && { style: inlineStyles?.list })}
      {...(swipeEvents && { ...swipeEvents })}
    >
      {Children.map(children, (child, index) => (
        <li
          className={classnames(classes.slide, {
            [classes.slide_active]: !maxVisibleItems && index === activeSlideIndex,
            [styles?.slide]: !!styles?.slide,
            [styles?.slide_active]: !maxVisibleItems && !!styles?.slide_active && index === activeSlideIndex,
          })}
          key={index}
          {...(inlineStyles?.listItem && { style: inlineStyles?.listItem })}
        >
          {child}
        </li>
      ))}
    </ul>
  ),
);

const Carousel = ({ children, activeSlideIndex, options = {} }) => {
  const {
    gap = 20,
    maxVisibleItems,
    styles = {},
    blueArrow = false,
    hideDisabledArrow = false,
    getActiveSlideIndex,
    autoScroll = false,
  } = options;

  const ref = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const [disableLeftArrow, setDisableLeftArrow] = useState(true);
  const [disableRightArrow, setDisableRightArrow] = useState(false);

  const { adjustedGap, style } = useMemo(() => {
    const gapValue = maxVisibleItems === 1 ? 0 : gap;

    if (maxVisibleItems > 0) {
      return {
        adjustedGap: gapValue,
        style: {
          flex: `1 0 calc(${100 / maxVisibleItems}% - ${(gapValue * (maxVisibleItems - 1)) / maxVisibleItems}px)`,
        },
      };
    }

    return {
      adjustedGap: gapValue,
      style: null,
    };
  }, [maxVisibleItems, gap]);

  const scrollCallback = useCallback(
    newIndex => {
      if (ref?.current) {
        const width = ref?.current?.clientWidth + adjustedGap / 2;
        const scrollLeft = width * newIndex;
        ref.current.scrollLeft = scrollLeft;

        const lastChildNode = ref?.current?.lastElementChild;
        const offsetRight = lastChildNode?.offsetLeft + lastChildNode?.offsetWidth;

        setDisableLeftArrow(scrollLeft === 0);
        setDisableRightArrow(scrollLeft + width >= offsetRight);
      }
    },
    [adjustedGap],
  );

  useEffect(() => {
    scrollCallback(activeIndex);
    if (typeof getActiveSlideIndex === 'function') {
      getActiveSlideIndex(activeIndex);
    }
  }, [scrollCallback, activeIndex, getActiveSlideIndex, activeSlideIndex]);

  const cancelPropagation = useCallback(e => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e?.stopPropagation) {
      e.stopPropagation();
    }
  }, []);

  const onScrollLeft = useCallback(
    e => {
      cancelPropagation(e);

      setActiveIndex(state => (state > 0 ? state - 1 : Children.count(children) - 1));
    },
    [cancelPropagation, children],
  );

  const onScrollRight = useCallback(
    e => {
      cancelPropagation(e);

      setActiveIndex(state => (state < Children.count(children) - 1 ? state + 1 : 0));
    },
    [cancelPropagation, children],
  );

  useEffect(() => {
    if (activeSlideIndex >= 0) {
      const childNode = ref?.current?.children?.[activeSlideIndex];
      const clientWidth = ref?.current?.clientWidth + adjustedGap / 2;
      const scrollLeft = ref?.current?.scrollLeft;
      const offsetLeft = childNode?.offsetLeft;

      if (scrollLeft + clientWidth <= offsetLeft) {
        onScrollRight();
      } else if (scrollLeft > offsetLeft) {
        onScrollLeft();
      }
    }
  }, [activeSlideIndex, adjustedGap, onScrollRight, onScrollLeft]);

  const swipeEvents = useSwipeEvents({ onSwipeLeft: onScrollRight, onSwipeRight: onScrollLeft });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (autoScroll) {
      const interval = setInterval(() => {
        onScrollRight();
      }, 6000);

      return () => clearInterval(interval);
    }
  }, [autoScroll, onScrollRight]);

  return (
    <div className={classnames(classes.root, { [styles.root]: !!styles.root })} onClick={cancelPropagation}>
      {hideDisabledArrow || disableLeftArrow ? null : (
        <div
          className={classnames(classes.icon, classes.icon__left, {
            [classes.icon__disabled]: disableLeftArrow,
            [classes.icon__blue]: blueArrow,
            [styles.leftArrow]: !!styles.leftArrow,
          })}
          onClick={onScrollLeft}
        >
          <ArrowBackIosNewIcon />
        </div>
      )}
      <ListItems
        ref={ref}
        swipeEvents={swipeEvents}
        activeSlideIndex={activeSlideIndex}
        inlineStyles={{
          list: {
            gap: `${adjustedGap}px`,
            padding: maxVisibleItems === 1 ? 0 : '4px',
            marginLeft: maxVisibleItems === 1 ? 0 : '-4px',
            marginRight: maxVisibleItems === 1 ? 0 : '-4px',
          },
          listItem: style,
        }}
        styles={styles}
      >
        {children}
      </ListItems>
      {hideDisabledArrow || (disableRightArrow && !autoScroll) ? null : (
        <div
          className={classnames(classes.icon, classes.icon__right, {
            [classes.icon__blue]: blueArrow,
            [styles.rightArrow]: !!styles.rightArrow,
          })}
          onClick={onScrollRight}
        >
          <ArrowForwardIosIcon />
        </div>
      )}
    </div>
  );
};

const CarouselWrapper = ({ disabled, children, withoutWrapper, ...props }) => {
  const { gap, styles = {} } = props?.options || {};

  if (disabled) {
    if (withoutWrapper) {
      return children;
    }

    return (
      <ListItems
        inlineStyles={{
          list: {
            gap: gap > 0 ? `${gap}px` : null,
          },
        }}
        styles={styles}
        wrap
      >
        {children}
      </ListItems>
    );
  }

  return <Carousel {...props}>{children}</Carousel>;
};

export default CarouselWrapper;
