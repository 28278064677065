import { useEffect, useMemo, useState } from 'react';

const useOnScrollBeyondElement = ({ topPosition = 0, enabled = true } = {}) => {
  const [isScrolledBeyond, setIsScrolledBeyond] = useState(false);
  const [ref, setRef] = useState(null);
  const shouldTrackScroll = useMemo(() => enabled && typeof window !== 'undefined', [enabled]);

  useEffect(() => {
    const element = ref;
    let observer = null;
    if (shouldTrackScroll) {
      observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            const rect = entry.boundingClientRect;
            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

            // NOTE: Position of the observed element relative to the viewport
            const topEdge = rect.top;
            const bottomEdge = viewportHeight - rect.bottom;

            if (topEdge > bottomEdge) {
              // NOTE: When the element is below viewport, user needs to scroll to reach to this element, hence its set to false
              setIsScrolledBeyond(false);
            } else {
              setIsScrolledBeyond(!entry.isIntersecting);
            }
          });
        },
        {
          threshold: 0,
          rootMargin: `-${topPosition}px 0px 0px 0px`,
        },
      );

      if (element) {
        observer.observe(element);
      }
    }

    return () => {
      if (element && shouldTrackScroll) {
        observer.unobserve(element);
      }
    };
  }, [ref, shouldTrackScroll, topPosition]);

  return { ref: setRef, isScrolledBeyond };
};

export default useOnScrollBeyondElement;
