import React, { memo, useMemo, useState } from 'react';
import classnames from 'classnames';
import * as Yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Formik, Form, Field } from 'formik';
import { useSelector } from 'react-redux';
import useTracking from 'components/Globals/Analytics';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton, { TertiaryButton } from 'components/uiLibrary/LinkButton';
import TextField from 'components/Inputs/TextField';
import Modal from 'components/uiLibrary/Modal';
import Drawer from 'components/uiLibrary/Drawer';
import Typography from 'components/uiLibrary/Typography';
import Button from 'components/uiLibrary/Button';

import { SHARE_ACCESS_HUBSPOT_FORM_ID, TP } from 'constants/consts';
import { ENTITY_TYPE, SHARE_ACCESS_TYPE, ACCESS_TYPE } from 'constants/index';
import endpointConstants from 'constants/endpointConstants';

import { trackShareAccess } from 'utils/tracking';
import usePageContext from 'utils/hooks/usePageContext';
import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import useEntityEditPermission from 'utils/hooks/useEntityEditPermission';
import { useDialogs } from 'utils/hooks/useDialogs';
import { useUserData, useActiveProfileData } from 'utils/hooks/useAuthenticatedUser';
import * as API from 'utils/API';
import { submitHubspotForm } from 'utils/formUtils';

import { selectLanguage } from 'store/selectors';
import { useTranslation } from 'src/i18n';

import classes from './EntityShareAccess.module.scss';
import { GOOGLE_OLD_TRACKING_SERVICES } from '../Analytics/constants';

const shareValidation = Yup.object().shape({
  firstName: Yup.string().required(`${TP}.m_LOGIN_REQUIRED`),
  lastName: Yup.string().required(`${TP}.m_LOGIN_REQUIRED`),
  email: Yup.string()
    .email(`${TP}.m_MAILNONVALID`)
    .required(`${TP}.m_LOGIN_REQUIRED`),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref(`email`)], `${TP}.m_LOGIN_EMAILSNOTMATCHED`)
    .required(`${TP}.m_LOGIN_REQUIRED`),
});

// Button prop needs to checked with userteam
const EntityShareAccess = ({
  entityType,
  entity,
  button,
  setTooltipStatus = () => {},
  isMoreAction = false,
  trackingData,
  withoutText = false,
}) => {
  const { t } = useTranslation('NS_DISPLAY_V4');
  const track = useTracking();
  const { setNoSubscriptionPopup, setPendingAccessPopup, setSubscriptionNudgesPopup } = useNotificationPopup();
  const { isVerificationPending, hasSubscription, isArtistInRoster, hasTargetProfileAccess } = useEntityEditPermission({
    entityType,
    entity,
  });
  const { setIsRequestAccessModalOpen } = useDialogs();
  const [isModalOpen, setModalOpen] = useState(false);
  const [submitCompletedEmail, setSubmitCompletedEmail] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const activeProfile = useActiveProfileData();
  const { permissions } = usePageContext();
  const targetEntity = entity || activeProfile;
  // Need to check with userteam
  const userData = useUserData();
  const language = useSelector(selectLanguage());
  const isMobile = useMediaQuery('(max-width:600px)');

  const formType = SHARE_ACCESS_TYPE[entityType];

  const submitDataToHubspot = formValues => {
    const portalId = process.env.HUBSPOT_REGISTER_KEY;
    const formId = SHARE_ACCESS_HUBSPOT_FORM_ID[formType];
    const payload = {
      fields: [
        { name: 'email', value: userData.email },
        { name: 'receiver_email__share_access_', value: formValues.email },
        { name: 'entity_share_access', value: entity.name || '' },
        { name: 'entity_id_share_access', value: entity.id || '' },
        { name: 'hs_language', value: language },
        { name: 'user_id__database_', value: userData.id || '' },
      ],
    };

    submitHubspotForm(portalId, formId, payload);
  };

  const shareAccess = (values, setSubmitting) => {
    setErrorMessage(null);
    const requestData = {
      user: { firstName: values.firstName, lastName: values.lastName, email: values.email },
      sendEmail: true,
      ...(entityType === ENTITY_TYPE.ARTIST && {
        profile: { id: targetEntity?.id },
        shareAccess: true,
        accessType: ACCESS_TYPE.PROFILE,
      }),
    };

    if (entityType === ENTITY_TYPE.ARTIST) {
      requestData.accessType = ACCESS_TYPE.PROFILE;
      requestData.profile = targetEntity;
    } else if (entityType === ENTITY_TYPE.MANAGER) {
      requestData.accessType = ACCESS_TYPE.AGENCY;
      requestData.agency = targetEntity;
    } else if (entityType === ENTITY_TYPE.ORGANIZATION) {
      requestData.accessType = ACCESS_TYPE.ORGANIZATION;
      requestData.organization = targetEntity;
    }

    API.create(endpointConstants.PROFILE_ACCESSES, requestData)
      .then(() => {
        setSubmitCompletedEmail(values.email);
        track.click(trackShareAccess({ 'Shared access': requestData }), GOOGLE_OLD_TRACKING_SERVICES);
      })
      .catch(error => setErrorMessage(error.message))
      .finally(() => {
        if (formType) {
          submitDataToHubspot(values);
        }
        setSubmitting(false);
      });
  };

  const titleText = submitCompletedEmail
    ? t(`${TP}.FN_ACCESS_SHARED`)
    : t((button && `${TP}.SHARE_INVITE_NEW_USER`) || `${TP}.FN_SHARE_ACCESS`);

  const subTextParagraph = (
    <Typography
      variant="h4"
      className={classnames(classes.subTitle, { [classes.submitedSubtitle]: !!submitCompletedEmail })}
    >
      {submitCompletedEmail
        ? t(`${TP}.FN_ACCESS_SHARED_TEXT`, { email: submitCompletedEmail })
        : t(`${TP}.FN_ACCESS_SHARE_TEXT`)}
    </Typography>
  );

  const FormInfo = () => (
    <Formik
      enableReinitialize
      validationSchema={shareValidation}
      initialValues={{ firstName: '', lastName: '', email: '', confirmEmail: '' }}
      onSubmit={(values, { setSubmitting }) => shareAccess(values, setSubmitting)}
    >
      {({ isSubmitting }) => (
        <Form className={classes.modalBody__form} autoComplete="off">
          <Field
            name="firstName"
            required
            className={classes.modalBody__form_field}
            placeholder={t(`${TP}.m_FIRSTNAME`)}
            label={t(`${TP}.m_FIRSTNAME`)}
            component={TextField}
            variant="outlined"
            fullWidth
          />
          <Field
            name="lastName"
            required
            className={classes.modalBody__form_field}
            placeholder={t(`${TP}.m_REGISTRATION_LASTNAME`)}
            label={t(`${TP}.m_REGISTRATION_LASTNAME`)}
            component={TextField}
            variant="outlined"
            fullWidth
          />
          <Field
            name="email"
            required
            className={classes.modalBody__form_field}
            placeholder={t(`${TP}.m_EMAIL`)}
            label={t(`${TP}.m_EMAIL`)}
            component={TextField}
            type="email"
            variant="outlined"
            fullWidth
          />
          <Field
            name="confirmEmail"
            required
            className={classes.modalBody__form_field}
            placeholder={t(`${TP}.m_REGISTRATION_CONFIRMEMAIL`)}
            label={t(`${TP}.m_REGISTRATION_CONFIRMEMAIL`)}
            component={TextField}
            variant="outlined"
            fullWidth
          />
          <div className={classes.errorMessage}>{errorMessage}</div>
          <div className={classes.modalBody__form_actions}>
            <div className={classes.modalBody__form_actions_buttons}>
              <Button
                className={classes.modalBody__form_actions_buttons_cancel}
                variant="secondary"
                onClick={() => {
                  setModalOpen(false);
                  setTooltipStatus({ tooltip: false, modal: false });
                }}
              >
                {t(`${TP}.m_CANCEL`)}
              </Button>
              <Button type="submit" className={classes.modalBody__form_actions_buttons_share} disabled={isSubmitting}>
                {t(button ? `${TP}.INVITE` : `${TP}.m_SOCIAL_SHARE`)}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  const handleShareAccess = () => {
    setModalOpen(true);
    setTooltipStatus({ tooltip: false, modal: true });
  };

  const canShareAccess = useMemo(() => {
    switch (entityType) {
      case ENTITY_TYPE.ARTIST:
        return permissions.artistPermissions.canShareAccess;
      case ENTITY_TYPE.ORGANIZATION:
        return permissions.organizationPermissions.canShareAccess;
      default:
        return false;
    }
  }, [permissions, entityType]);

  const handleCheckPermissionPopup = event => {
    if (isArtistInRoster) {
      if (hasTargetProfileAccess && hasSubscription) {
        handleShareAccess();
      } else {
        setIsRequestAccessModalOpen(true);
      }
      return;
    }

    if (!hasSubscription) {
      event.preventDefault();

      if (entityType === ENTITY_TYPE.ARTIST) {
        setSubscriptionNudgesPopup({ isOpen: true });
        return;
      }

      setNoSubscriptionPopup(true);
      return;
    }

    if (isVerificationPending) {
      event.preventDefault();
      setPendingAccessPopup(true);
      return;
    }

    if (canShareAccess) {
      handleShareAccess();
    }
  };

  return (
    <>
      {button ? (
        <Button entityType={entityType} onClick={() => setModalOpen(true)} elementId="shareAccess" variant="secondary">
          {t(`${TP}.INVITE`)}
        </Button>
      ) : (
        <>
          {isMoreAction ? (
            <li className={classes.entityShareAccess__item}>
              <SpriteIcon
                icon="group_add_black_24dp"
                id="shareAccess"
                size={16}
                className={classes.entityShareAccess__icon}
              />
              <LinkButton
                styles={{ root: classes.entityShareAccess__link }}
                onClick={handleCheckPermissionPopup}
                variant="text"
                disableUnderline
                trackingData={trackingData}
              >
                {t(`${TP}.FN_SHARE_ACCESS`)}
              </LinkButton>
            </li>
          ) : (
            <TertiaryButton
              leftIcon={
                <SpriteIcon
                  icon="group_add_black_24dp"
                  id="shareAccess"
                  size={16}
                  className={classes.entityShareAccess__icon}
                />
              }
              onClick={handleCheckPermissionPopup}
              size="medium"
              disableUnderline
              styles={{
                root: classnames(classes.entityShareAccess__button, {
                  [classes.entityShareAccess__button_iconOnly]: withoutText,
                }),
              }}
              trackingData={trackingData}
            >
              {!withoutText && t(`${TP}.FN_SHARE_ACCESS`)}
            </TertiaryButton>
          )}
        </>
      )}
      {isMobile ? (
        <Drawer
          isOpen={isModalOpen}
          onClose={() => {
            setModalOpen(false);
            setTooltipStatus({ tooltip: false, modal: false });
          }}
          title={titleText}
        >
          <div className={classes.modalBody}>
            {subTextParagraph}
            {submitCompletedEmail ? (
              <Button
                className={classes.continueBtn}
                onClick={() => {
                  setModalOpen(false);
                  setSubmitCompletedEmail(null);
                  setTooltipStatus({ tooltip: false, modal: false });
                }}
              >
                {t(`${TP}.m_CONTINUE`)}
              </Button>
            ) : (
              <FormInfo />
            )}
          </div>
        </Drawer>
      ) : (
        <Modal
          isOpen={isModalOpen}
          onClose={() => {
            setModalOpen(false);
            setTooltipStatus({ tooltip: false, modal: false });
          }}
          title={titleText}
          styles={{
            modalRoot: classes.modalRoot,
          }}
        >
          <div className={classes.modalBody}>
            {subTextParagraph}
            {submitCompletedEmail ? (
              <Button
                className={classes.continueBtn}
                onClick={() => {
                  setModalOpen(false);
                  setSubmitCompletedEmail(null);
                  setTooltipStatus({ tooltip: false, modal: false });
                }}
              >
                {t(`${TP}.m_CONTINUE`)}
              </Button>
            ) : (
              <FormInfo />
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default memo(EntityShareAccess);
