export const colors = {
  adobeRed: 'rgb(217, 43, 47)', // #d92b2f
  black: 'rgb(0, 0, 0)',
  textColor: 'rgb(0, 0, 0, 0.87)', // #000000
  modalBlur: 'rgb(0, 0, 0, 0.8)',
  black1: 'rgba(0, 0, 0, 0.40)',
  black2: 'rgba(0, 0, 0, 0.20)',
  blackMineShaft: 'rgb(40, 40, 40)', // #282828
  blackOpacity12: 'rgba(0, 0, 0, 0.12)', // #000000
  blackOpacity20: 'rgba(0, 0, 0, 0.20)', // #000000
  blackOpacity15: 'rgba(0, 0, 0, 0.15)', // #000000
  blackOpacity4: 'rgba(0, 0, 0, 0.04)', // #000000
  blue: 'rgb(4, 145, 255)', // #0491ff
  blueLight: 'rgb(0, 122, 254)', // #007afe
  blueLight1: 'rgb(160,160,255)', // #a0a0ff
  blue1: 'hsl(208, 100%, 97%)', // #f0f8ff
  blue2: 'hsl(208, 100%, 90%)', // #f0f8ff
  blue3: 'rgb(0, 103, 197)', // #0067C5
  blue3Opacity10: 'rgb(0, 103, 197, 0.10)', // #0067C5
  blue3Opacity20: 'rgb(0, 103, 197, 0.20)', // #0067C5
  blue3Opacity22: 'rgb(0, 103, 197, 0.22)', // #0067C5
  blue3Opacity31: 'rgb(0, 103, 197, 0.31)', // #0067C5
  blue3Opacity40: 'rgb(0, 103, 197, 0.40)', // #0067C5
  blue3Opacity50: 'rgb(0, 103, 197, 0.50)', // #0067C5
  blue4: 'rgba(0, 80, 153)', // #005099,
  blue5: 'rgba(114, 158, 199)', // #729EC7,
  blue6: 'rgba(0, 87, 166)', // #0057A6,
  blue7: 'rgba(231, 242, 250, 1)', // #E7F2FA
  blue8: 'rgb(199,222,242)', // #C7DEF2
  cancelButton: 'rgb(87, 87, 87)', // #575757
  cardTitle: 'rgb(95,95,95)', // #8b8b8b
  darkBlue: 'rgb(0,82,204)', // #0052cc
  darkGreen: 'rgb(0,102,68)', // #006644
  darkPurple: 'rgb(82,67,170)', // #0052cc
  fieldsBorderGray: 'rgba(179, 179, 179, 1)', // #b3b3b3
  linesGray: 'rgba(151, 151, 151, 0.2)',
  borderGray: '#ECE9E9',
  linesDarkerGray: 'rgba(151, 151, 151, 0.5)',
  primary: 'rgb(243, 109, 42)', // #f36d2a
  lightPrimary: 'rgb(243, 109, 42, 0.1)', // #f36d2a
  btnDisabled: 'rgb(243, 109, 42, 0.5)', // #f36d2a
  red: 'rgb(217, 43, 47)', // #D92B2F
  redCancel: 'rgb(192, 0, 0)', // #c00000
  redDelete: 'rgb(160, 75, 75)', // #A04B4B
  redBorderDelete: 'rgb(135,63,63)', // #873F3F
  redLightenCancel: 'rgba(255, 237, 237, 1)', // #FFEDED
  red1: 'rgb(255, 247, 247)', // #FFF7F7
  red2: 'rgba(255, 197, 169, 1)', // #FFC5A9
  red3: 'rgba(191, 0, 0, 1)', // #BF0000
  red3Opacity20: 'rgba(191, 0, 0, 0.2)', // #BF0000
  red4: 'rgba(117, 26, 26, 1)', // #751A1A
  red5: 'rgb(207, 20, 43)', // #CF142B
  subTitle: 'rgb(95,95,95)', // #5f5f5f
  lightTitle: 'rgb(139,139,139)', // #8b8b8b
  text: 'rgb(139, 139, 139)', // 3a3a3a
  darkText: 'rgb(58,58,58)', // 3a3a3a -Actual 3a3a3a
  darkerGray: 'rgb(237, 237, 237)', // #ededed
  textGray: 'rgba(0, 0, 0, 0.38)',
  mobileTitleGray: 'rgba(87, 87, 87, 0.7)',
  title: 'rgb(45, 45, 45)', // '#2d2d2d'
  white: 'rgb(255, 255, 255)',
  productionChip: 'rgb(255,93,97)',
  concertChip: 'rgb(0,155,78)',
  contentDivider: 'rgb(151,151,151,0.2)', // '#979797',
  linkHover: 'rgb(23, 77, 120)', // '#174d78',
  primaryTitleColor: 'rgb(47, 47, 47)', // '#2f2f2f',
  secondaryTitleColor: 'rgb(54, 54, 54)', // '#363636',
  rose: 'rgb(255,145,145)', // #ff9191
  websiteLinkColor: 'rgb(60, 60, 60)', // '#3c3c3c',
  websiteLinkColorBg: 'rgb(216, 216, 216, 0.2)', // '#d8d8d8',
  gray: 'rgb(58, 58, 58, 0.5)', // '#3a3a3a',
  grey: 'rgb(234, 234, 234)', // #eaeaea
  purple: 'rgb(173, 25, 25)', // #ad1919
  lightGreen: 'rgb(109, 212, 0)', // #6dd400
  purpleLight: 'rgb(222, 122, 122)', // #de7a7a
  orange: 'rgb(253, 124, 40)', // #fd7c28
  grey4: 'hsl(0, 0%, 44%)',
  lightGrey1: 'rgb(151,151,151)', // '#979797',
  background: {
    biscue: 'rgb(238,225,219)', // #eee1db
    dark: 'rgb(240, 240, 240)',
    default: 'rgb(250, 250, 250)',
    gray: 'rgb(238, 238, 238)', // #eeeeee
    lightGrey: 'rgb(216,216,216)', // d8d8d8
    lightGrey1: 'rgb(216,216,216,0.2)', // d8d8d8
    grayCard: 'rgb(245, 245, 245)', // #f5f5f5
    lightGrey3: 'rgb(241, 241, 241)', // #F1F1F1
    grayLight: 'rgb(248, 248, 248)', // #f8f8f8
    optionBg: 'rgb(0, 0, 0, 0.04)', // #f8f8f8
    grayIcon: 'rgb(221,221,221)', // #dddddd
    gradiant: 'repeating-linear-gradient(-65deg, #b46060, #b46060 5px, rgb(110,6,6,0.7) 5px, rgb(110,6,6,0.7) 10px)',
    blue: 'rgba(0, 103, 197, 0.1)',
    black: 'rgb(30, 29, 31)', // #1e1d1f
    black2: 'rgb(46, 45, 49)', // #2e2d31
    orangeLight: 'rgb(255, 247, 243)', // #fff7f3
    lightGrey2: 'rgba(235,235,235, 0.6)',
    red: 'rgb(193,24,24)',
    lightRed1: 'rgb(250,218,213)', // #FADAD5
    lightBlue: 'rgb(161,205,245)', // #A1CDF5
    lightBlue1: 'rgb(225, 237, 249)', // #E1EDF9
    lightBlue2: 'rgb(229, 240, 249)', // #E5F0F9
  },
  grey1: 'rgb(127, 127, 127)', // #7f7f7f,
  grey2: 'rgb(111, 111, 111)', // #6f6f6f,
  grey2Opacity60: 'rgb(111, 111, 111, 0.6)', // #6f6f6f,
  blockIcon: 'rgb(216, 77, 77)', // #d84d4d
  grey3: 'rgb(58, 58, 58)', // #3a3a3a
  grey3Opacity20: 'rgb(58, 58, 58, 0.2)', // #3a3a3a
  grey6: 'rgb(197,197,197)', // #c5c5c5
  grey7: 'rgb(255,255, 255)', // #E1E1E1
  grey8: 'rgb(79, 79, 79)', // #4F4F4F
  grey9: 'rgb(242, 242, 242)', // #F2F2F2
  grey10: 'rgb(200, 200, 200)', // #C8C8C8
  grey11: 'rgba(229, 229, 229, 1)', // #E5E5E5
  grey12: 'rgb(233, 238, 241, 1)', // #e9eef1
  grey13: 'rgb(156,156,156)', // #9c9c9c
  grey14: 'rgb(135, 135, 135)', // #878787
  grey15: 'rgba(224, 224, 224, 1)', // #e0e0e0
  grey16: 'rgba(117, 117, 117, 1)', // #757575
  grey17: 'rgba(124, 124, 124, 1)', // #7C7C7C
  greenChip: 'rgb(6, 110, 15)', // #066e0f
  blueChip: 'rgb(0, 103, 197)', // #0067c5
  blueChip1: 'rgb(27, 148, 252)', // #1B94FC
  primary2: 'rgb(194, 87, 36)',
  border: {
    grey: 'rgb(235,235,235)', // ebebeb
    lightGrey: 'rgb(151,151,151,0.2)', // d8d8d8
    lightGrey1: 'rgb(222,222,222)', // dedede
    grey1: 'rgb(223, 223, 223)', // dfdfdf
    blue1: 'rgba(0, 103, 197, 0.5)',
    orange: 'rgb(243, 110, 42)', // #f36e2a
    greyWithOpacity: 'rgb(58,58,58,0.2)',
  },
  darkGrey: 'rgb(116,119,118)', // #747776
  green: 'rgb(101, 137, 1)', // #658901
  green1: 'rgb(39, 174, 96)', // #27AE60,
  green2: 'rgb(80,153,87)', // #509957
  green3: 'rgb(44,114,50)', // #2C7232
  green1Opacity20: 'rgb(39, 174, 96, 0.2)',
  lightBlack: 'rgba(0, 0, 0, 0.52)',
  fbBlue: 'rgb(59,87,163)', // #3B57A3
  lightPink: 'rgb(253,245,245)', // fdf5f5
  lightGrey: 'rgb(250, 250, 250)', // #fafafa
  orange2: 'rgb(249,112,46)', // F9702E
  orange3: 'rgb(241,208,194)', // #F1D0C2
  orange4: 'rgb(243,110,43)', // #F36E2B
  yellow: 'rgb(255, 204, 0)', // #FFCC00
  clearGray: 'rgba(0, 0, 0, 0.6)',
  yellowLight: 'rgb(253, 247, 227)', // #FDF7E3
  grey5: 'rgb(247,247,247)', // #F7F7F7
  darkGrey2: 'rgb(78,78,78)', // #4E4E4E,
  darkGrey2Light: 'rgb(78,78,78,0.9)', // #4E4E4E,
  seaBlue: 'rgb(0,141,201)', // #008dc9,
  SeaGreen: 'rgb(87,145,87)', // #579157,
  strongYellow: 'rgb(207,185,19)', // #CFB913
  seaPink: 'rgb(189,102,129)', // BD6681
  darkBlack: 'rgb(1,1,1)', // #010101
  mediumWood: 'hsl(22, 31%, 51%)', // #a9785c
  avatarGrey: 'rgb(189,189,189)',
  orangeRed: 'rgb(194,87,36)', // #C25724
  lightRed: 'rgb(250,239,233)', // #FAEFE9
  borderBlackSeperator: 'rgb(102,102,102)', // #a9785c
  borderBlackSeperator1: 'rgb(145,145,145)',
  blackText: 'rgb(239,239,239, 1)', // #efefef
  gold: 'rgb(242, 201, 76)', // #F2C94C
  goldOpacity20: 'rgb(242, 201, 76, 0.2)', // #F2C94C
};

export const fontSize = {
  basic: 16,
  large: 18,
  medium: 14,
  h2: 28,
  h3: 24,
  h6: 20,
  regularTitle: 16,
  smallTitle: 12,
  subTitle: '2.67rem',
  table: 13,
  textFieldLabel: 16,
  title: 32,
  largeTitle: '2rem',
  mdTitle: '1.5rem',
  description: 15,
  heading: 26,
  eleven: 11,
  ten: 10,
  customLargeTitle: '1.3rem',
  profilePageTitle: 32,
};

export const lineHeight = 1.73;

export const newLineHeight = 1.43;

export const letterSpacing = {
  subTitle: 2.25,
};

export const borderRadius = {
  fields: 2.5,
  button: 2,
  input: 4,
};

export const boxShadows = {
  block: '0 2px 6px 0 rgba(151, 151, 151, 0.35)',
  sideCard: '0 2px 9px 0 rgba(0, 0, 0, 0.1)',
  card: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
  expandedCard:
    '0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)',
  table: '0 1px 7px 0 rgba(180, 180, 180, 0.5)',
};

export const border = {
  lightGrey: 'solid 0.5px rgba(151, 151, 151, 0.5)',
  input: `1px solid ${colors.fieldsBorderGray}`,
};

export const formBlock = {
  backgroundColor: colors.background.grayLight,
  borderRadius: borderRadius.button,
  boxShadow: boxShadows.block,
  padding: 20,
};

export const inputSubmitStyles = {
  borderRadius: 2,
  WebkitAppearance: 'none',
  backgroundColor: colors.primary,
  color: colors.white,
  border: 'none',
  fontFamily: 'Roboto',
  fontSize: 15,
  fontWeight: 'bold',
  fontStyle: 'normal',
  letterSpacing: 0,
  padding: '11px 7px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgb(246, 152, 105)',
  },
  '&:disabled': {
    backgroundColor: colors.btnDisabled,
  },
  overrideFontSizeForMobile: '16px !important',
};

export const inputProceedStyles = {
  backgroundColor: 'rgba(0, 103, 197, 0.8)',
  border: '1px solid rgba(0, 80, 153, 0.3)',
  borderRadius: 4,
  WebkitAppearance: 'none',
  color: colors.white,
  fontFamily: 'Roboto',
  fontSize: 15,
  fontWeight: 'bold',
  fontStyle: 'normal',
  letterSpacing: 0,
  padding: '11px 7px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0, 103, 197, 0.6)',
  },
  '&:disabled': {
    backgroundColor: 'rgba(0, 103, 197, 0.4)',
  },
  overrideFontSizeForMobile: '16px !important',
};

export const maskTextStyles = {
  '-webkit-user-select': 'none',
  '-moz-user-select': 'none',
  '-ms-user-select': 'none',
  'user-select': 'none',
  color: 'transparent !important',
};

export const smallModal = theme => ({
  width: 504,
  left: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    maxWidth: 504,
  },
});

export const fontFamilies = {
  PLAYFAIR: 'Playfair Display',
  ROBOTO_BOLD: 'RobotoBold',
  ROBOTO: 'Roboto',
  ROBOTO_ITALIC: 'RobotoItalic',
  ROBOTO_MEDIUM: 'RobotoMedium',
};
