import { useCallback, useState } from 'react';

const useSwipeEvents = ({ onSwipeLeft, onSwipeRight, distance = 50 }) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const onTouchStart = useCallback(e => {
    setTouchEnd(null);
    setTouchStart(e?.targetTouches?.[0]?.clientX);
  }, []);

  const onTouchMove = useCallback(e => setTouchEnd(e?.targetTouches?.[0].clientX), []);

  const onTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) {
      return;
    }

    const swiped = touchStart - touchEnd;
    const isLeftSwipe = swiped > distance;
    const isRightSwipe = swiped < -distance;

    if (isLeftSwipe) {
      onSwipeLeft();
    } else if (isRightSwipe) {
      onSwipeRight();
    }
  }, [onSwipeRight, onSwipeLeft, touchStart, touchEnd, distance]);

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};

export default useSwipeEvents;
