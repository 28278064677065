import React from 'react';
import classnames from 'classnames';
import Typography from 'components/uiLibrary/Typography';
import EntityName from 'components/Globals/EntityName';
import ImpressionTracker from 'utils/components/impressionTracker';

import { getProductionCredits, getProductionTitle } from 'utils/productions';
import { getComposersFromCreators } from 'utils/composer';
import { createDate } from 'utils/date';
import { ENTITY_TYPE } from 'constants/index';

import SeparatorList from 'components/uiLibrary/SeparatorList';
import classes from './CardWithProductionInfo.module.scss';

const useProductionInfo = ({ production, show = true, trackingData }) => {
  if (!show || !production || Object.keys(production).length === 0) {
    return null;
  }

  let info = null;
  const productionMaxDate = createDate(production?.maxDate);
  const hasUpcomingPerformance =
    (createDate().isSame(productionMaxDate, 'day') || productionMaxDate?.isAfter(createDate(), 'day')) &&
    production?.performances?.length > 0;
  const title = getProductionTitle(production);
  const composers = getComposersFromCreators(production?.productionWorks?.[0]?.work?.creators);
  const { producers } = getProductionCredits(production);

  if (title) {
    info = (
      <Typography variant="p" size={12} color="white">
        {production.periode && hasUpcomingPerformance && (
          <Typography variant="span" weight="bold" color="inherit">
            {`(${production?.periode}) `}
          </Typography>
        )}
        {title && (
          <Typography variant="span" weight="bold" color="inherit">
            {/* Dont track this, production is being tracked at parent level, uainf ImpressionTracker */}
            <EntityName entityType={ENTITY_TYPE.PRODUCTION} entity={{ id: production?.id, name: title }} isRaw />
          </Typography>
        )}
        {composers.length > 0 && (
          <Typography variant="span" color="inherit" italic>
            {' '}
            <SeparatorList
              data={composers.map(composer => (
                <EntityName
                  entityType={ENTITY_TYPE.ARTIST}
                  entity={{ id: composer?.profile?.id, name: composer?.profile?.shortName || composer?.profile?.name }}
                  isRaw
                  trackingData={trackingData}
                />
              ))}
            />
          </Typography>
        )}
        {producers?.data?.length > 0 && (
          <Typography variant="span" color="inherit" weight="bold">
            {', '}
            {producers?.data?.map(producer => (
              <span
                className={classnames({
                  [classes.isBlur]: producer?.entity?.id === -1,
                })}
              >
                <EntityName
                  entity={producer?.entity}
                  key={producer?.entity?.id}
                  entityType={producer?.entityType}
                  isRaw
                  trackingData={trackingData}
                />
              </span>
            ))}
          </Typography>
        )}
      </Typography>
    );
  }

  return info;
};
const EMPTY_OBJ = {};
const CardWithProductionInfo = ({ children, production, disabled = false, styles, trackingData }) => {
  const productionInfo = useProductionInfo({ production, show: !disabled, trackingData });
  if (disabled) {
    return (
      <div
        className={classnames({
          [styles?.root]: !!styles?.root,
        })}
      >
        {children}
      </div>
    );
  }

  return (
    <ImpressionTracker
      data={
        productionInfo
          ? {
              ...trackingData,
              entityId: production?.id,
              entityName: production?.name || production?.slug,
              entityType: ENTITY_TYPE.PRODUCTION,
            }
          : EMPTY_OBJ
      }
      className={classnames(classes.root, {
        [styles?.root]: !!styles?.root,
      })}
    >
      <>
        {children}
        {productionInfo && (
          <div
            className={classnames(classes.root__footer, {
              [styles?.footer]: styles?.footer,
            })}
          >
            <div className={classes.root__production}>{productionInfo}</div>
          </div>
        )}
      </>
    </ImpressionTracker>
  );
};

export default CardWithProductionInfo;
